import React from 'react'
import PropTypes from 'prop-types'
import { linkPropType } from 'lib/prop-types'
import Button from 'components/Button'

const TransgressingImageContainer = props => {
  return (
    <div className="section section--padded-big">
      <div className="section__inner tc">
        <h2>{props.title}</h2>
        <div className="heater-details__container">
          {props.transgressingImages.map((imageProps, i) => (
            <div key={`image-${i}`} className="heater-detail">
              <h3>{imageProps.title}</h3>
              <div className="heater-detail__img">
                <img
                  src={imageProps.image.url}
                  alt={imageProps.image.description}
                />
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: imageProps.text }}
                className="heater-detail__text"
              />
              {imageProps.link ? (
                <div className="heater-detail__footer ">
                  <Button link={imageProps.link} className="btn--primary">
                    {imageProps.link.label}
                  </Button>
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

TransgressingImageContainer.propTypes = {
  title: PropTypes.string,
  transgressingImages: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string.isRequired,
        description: PropTypes.string,
      }),
      link: linkPropType,
    })
  ),
}

export default TransgressingImageContainer
